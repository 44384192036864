import { assertNotNull } from '@/helpers/typing'
import { ab } from './interface'
import { Experiment, OnboardingQuestionnaireVariant } from './types'

import { runTextUpdates } from '@/services/ab/13448_ab_experiment'
import { Doc } from '@/models/interfaces'
import { Sentry } from '@/services/sentry'
import { IS_PRERENDERING } from '@/init/settings'
import Cookies from 'js-cookie'
import { storage as storageHelper } from '@/helpers/storage'

/**
 * Initialize CloudFront experiments.
 *
 * Check A/B testing cookies set by CloudFront, send backend
 * requests and update frontend a/b data storage.
 */
export async function initAB(): Promise<void> {
  await newCopy13448Experiment(Experiment.new_copy_pdf_v2_13448, '/pdf')
  await aiGuideFlow13747()
  customCreatorLandingPages14358ClientID()
}

/**
 * Feature flag to enable/disable Google Billing. Disabled by default.
 *
 * This is not a real A/B experiment, i.e. the experiment is not activated on
 * the backend.
 */
export function googleBillingEnabled(): boolean {
  return ab.experimentVariant(Experiment.google_billing_7517) === 'yes'
}

/**
 * Apply the text changes on the public pages
 *
 * @param {Experiment} experiment - The A/B experiment containing the text changes.
 * @param {string} allowedUrlExpression - A regular expression to match allowed URLs,
 * on which the text changes should be applied.
 */
export async function newCopy13448Experiment(
  experiment: Experiment,
  allowedUrlExpression: string,
): Promise<void> {
  if (!window.location.pathname.includes(allowedUrlExpression)) {
    // Only start experiment on the right public page
    return
  }

  let result = ab.experimentVariant(experiment)

  if (result === null) {
    result = await ab.start(experiment)
  }

  if (result === 'yes') {
    runTextUpdates(experiment)
  }
}

/**
 * Get the variant of the ai_label_13537 experiment.
 * Returns boolean:
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export function aiLabel13537ExperimentVariant(): boolean {
  return ab.experimentVariant(Experiment.ai_label_13537) === 'yes'
}

/**
 * Decide wheter or not to render the ai content label component in
 * the book preview and summary pages.
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export async function aiLabel13537Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.ai_label_13537)

  if (result === null) {
    result = await ab.start(Experiment.ai_label_13537)
  }

  return result === 'yes'
}

/*
 * Show the human book cover or the fallback on public summary pages:
 - A: Show human book cover
 - B: Show fall back book cover

 Within the main app we will only apply the experiment variant to one book (the one of the visited summary page).
 */
export async function aiCover13860Experiment(): Promise<void> {
  // Don't run the experiment while generating the static pages
  if (IS_PRERENDERING) {
    return
  }

  const humanCover = document.querySelector('.book-cover__image')
  const fallbackCover = assertNotNull(document.querySelector('.fallback-cover'))

  if (!humanCover) {
    // Don't start the experiment if we don't have a human cover
    fallbackCover.classList.remove('opacity_cover_13860')
    return
  }

  const cfVariant13747 = Cookies.get('X-AB-AI-Guide-Flow-13747')
  const localVariant13747 = ab.experimentVariant(Experiment.ai_guide_flow_13747)
  if (cfVariant13747 || localVariant13747) {
    // The `ai_guide_flow_13747` experiment was started on CloudFront
    // Set excluded variant for this experiment
    // Note that we check for `ai_guide_flow_13747` experiment, but we exclude the user
    // from the (different) `ai_cover_flow_13860` experiment.
    // This is correct, we want to avoid the case where two experiments interact with
    // each other, see #14084 for details.
    await ab.start(Experiment.ai_cover_flow_13860, '_EXCLUDED_')
  } else {
    // Get the variant ("A" - human cover, "B" - automatic)
    let experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

    if (experimentVariant === null) {
      // Start the experiment
      experimentVariant = await ab.start(Experiment.ai_cover_flow_13860)
    }

    if (experimentVariant === 'B') {
      // Hide human cover

      humanCover.classList.add('hide_cover_13860')

      // Show fallback cover
      fallbackCover.classList.remove('hide_cover_13860')
    }
  }

  humanCover.classList.remove('opacity_cover_13860')
  fallbackCover.classList.remove('opacity_cover_13860')
}

/*
 * Check if we should show the fallback cover as part of the experiment ai_cover_flow_13860
 */
export function useFallbackCover_13860(doc: Doc): boolean {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)
  return experimentVariant === 'B' && doc.is_first_doc
}

/*
 * Send a sentry message if we have an unexpected case.
 * The user is in the experiment, this is experiment book, but we do not have human cover.
 * In this case the book should not be in the experiment.
 */
export function checkCoverFlow_13860(doc: Doc): void {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

  if (experimentVariant !== null && doc.is_first_doc && !doc.cover_image) {
    Sentry.captureMessage(
      `Unexpected case in experiment ${Experiment.ai_cover_flow_13860}: Book [${doc.url_slug}] doesn't have a cover but is in the experiment.`,
    )
  }
}

/**
 * Toggle the Link integration with Stripe.
 * Returns boolean:
 *   true - Enable Stripe Link
 *   false - Disable Stripe Link
 */
export async function stripeLinkIntegration13752Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.stripe_link_integration_13752)

  if (result === null) {
    result = await ab.start(Experiment.stripe_link_integration_13752)
  }

  return result === 'yes'
}

/*
 * Read experiment cookies set by CloudFront and transfer the experiment assignment to the local storage
 */
export async function aiGuideFlow13747(): Promise<void> {
  const cfVariant = Cookies.get('X-AB-AI-Guide-Flow-13747')
  const localVariant = ab.experimentVariant(Experiment.ai_guide_flow_13747)

  if (cfVariant && !localVariant) {
    await ab.start(Experiment.ai_guide_flow_13747, cfVariant)
  }
}

/**
 * Decide which plan card component to render on the billing page.
 * Returns boolean:
 *   true - Use the redesigned exercise page
 *   false - Don't use the redesigned exercise page
 */
export async function redesignedExercisePage13941Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.redesigned_exercise_page_13941)

  if (result === null) {
    result = await ab.start(Experiment.redesigned_exercise_page_13941)
  }

  return result === 'yes'
}

/**
 * Decide whether or not to use the new performance improvements for the app.
 * Returns boolean:
 *   true - Use performance improvements.
 *   false - Don't use performance improvements.
 */
export async function performanceOptimization14000Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.performance_optimization_14000)

  if (result === null) {
    result = await ab.start(Experiment.performance_optimization_14000)
  }

  return result === 'yes'
}

/**
 * Get the experiment variant from performance_optimization_14000 from local storage.
 * Returns boolean:
 *   true - Use performance improvements.
 *   false - Don't use performance improvements.
 */
export function performanceOptimization14000ExperimentVariant(): boolean {
  const result = ab.experimentVariant(Experiment.performance_optimization_14000)

  return result === 'yes'
}

/**
 * Determines the order of signup and onboarding questionnaire based on the experiment.
 * Returns the name of the name of the next page
 */
export async function onboardingQuestionnaire14335(): Promise<
  string | undefined
> {
  let result = ab.experimentVariant(Experiment.onboarding_questionnaire_14335)

  if (result !== null && ['A', 'E', '_EXCLUDED_'].includes(result)) {
    return undefined
  }

  const questionnaireDataStorage = storageHelper.getItem('sf_oq_data')

  const questionnaireData = questionnaireDataStorage
    ? JSON.parse(questionnaireDataStorage)
    : undefined

  if (result === 'B') {
    /* For variant B of the experiment:
     * - Redirect to the questionnaire only on the user's first visit
     * - Presence of questionnaire data indicates:
     *  1. User has completed the onboarding questions
     *  2. User is revisiting the signup page before finalizing signup
     */
    return !questionnaireData ? 'questionnaire' : undefined
  }

  /**
   * Exclude the user from the experiment if:
   *  - questionnaire already completed and experiment not started yet
   */

  const variant = questionnaireData ? '_EXCLUDED_' : undefined
  result = await ab.start(Experiment.onboarding_questionnaire_14335, variant)

  // Redirect to onboarding page on E variant
  if (result === 'E') {
    return 'questionnaire_short'
  }

  return result === 'B' ? 'questionnaire' : undefined
}

/**
 * Returns the variant of the onboarding experiment as string:
 *   A - Keep the old logic.
 *   B - Move signup page after the onboarding questionnaire.
 *   C - No onboarding questionnaire
 *   D - Show 1-screen onboarding before signup, then billing page
 *   E - Show 1-screen onboarding between signup and billing page
 */
export function onboardingQuestionnaire14335Variant(): OnboardingQuestionnaireVariant | null {
  const result = ab.experimentVariant(Experiment.onboarding_questionnaire_14335)
  return result as OnboardingQuestionnaireVariant | null
}

/**
 * Decide whether or not to show the subscription price converted into the user's local currency.
 *   true - Add the converted subscription price to the plan card.
 *   false - Don't add the converted subscription price to the plan card.
 */
export async function localizedPricing14281Experiment(
  isExcluded: boolean = false,
): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.localized_pricing_14281)

  if (result === null) {
    if (isExcluded) {
      await ab.start(Experiment.localized_pricing_14281, '_EXCLUDED_')
      return false
    }

    result = await ab.start(Experiment.localized_pricing_14281)
  }

  return result === 'yes'
}

/**
 * Set the user's client ID from the CloudFront cookie if
 * it exists and they got to the home page through a referral link.
 */
export function customCreatorLandingPages14358ClientID(): void {
  // Only run in the home page
  if (window.location.pathname !== '/') {
    return
  }

  const hasSource = window.location.search.indexOf('utm_source') > 0
  const hasMedium = window.location.search.indexOf('utm_medium') > 0
  const isCreatorURL = hasSource && hasMedium

  if (isCreatorURL) {
    ab.setVariantFromCookie(Experiment.custom_creator_landing_pages_14358)
  }
}
